<template>
    <main>
        <Player
            id="player-id-1"
            :sources="[
                { type: 'hls', file: 'https://vod.ome-test-2.bemyvega.dev/default_app_TZP2jwRJFmxjHDGgrGzb34-ptz/2022-11-18T10:58:25+0000/llhls.m3u8' },
                { type: 'hls', file: 'https://vod.ome-test-2.bemyvega.dev/default_app_TZP2jwRJFmxjHDGgrGzb34-fixed/2022-11-18T10:58:25+0000/llhls.m3u8' },
                { type: 'hls', file: 'https://vod.ome-test-2.bemyvega.dev/default_app_TZP2jwRJFmxjHDGgrGzb34-desktop/2022-11-18T10:58:18+0000/llhls.m3u8' }
            ]"
        />
    </main>
</template>

<script>
import Player from "../../components/Player.vue";

export default {
    name: "VodDemo",
    components: {
        Player
    },
    created() {
        // Hide sidebar
        document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    },
    beforeUnmount() {
        document.getElementById("app").firstElementChild.classList.remove("layout-static-sidebar-inactive");
    }
};
</script>
